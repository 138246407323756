import React, { useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { checkTriggerKeyIsUnique } from "../../api/triggers";

const TriggerShape = {
  key: "",
  responseToSender: "",
  enabled: false,
  messageToListeners: "",
  triggerNotificationList: false
};

const makeTriggerValidationSchema = accountId =>
  Yup.object().shape({
    key: Yup.string()
      .min(3, "Must be longer than 3 characters.")
      .required("Every trigger must have a key to look for in the message.")
      .test(
        "checkKeyIsUnique",
        "Must provide a unique key for this trigger.",
        async key => checkTriggerKeyIsUnique(accountId, key)
      ),
    responseToSender: Yup.string()
      .min(10, "Must be at least 10 characters long.")
      .required("Must provide a response to the sender."),
    triggerNotificationList: Yup.boolean(),
    messageToListeners: Yup.string().when("triggerNotificationList", {
      is: true,
      then: Yup.string()
        .min(10, "Must be at least 10 characters long.")
        .required(
          "Required because you would like to trigger a notification list."
        ),
      otherwise: Yup.string()
    }),
    enabled: Yup.boolean()
  });

export const TriggerModalModes = {
  CREATE: "CREATE",
  EDIT: "EDIT"
};

export const TriggerModal = ({
  mode = TriggerModalModes.CREATE,
  handleCreate,
  accountId,
  ...props
}) => {
  const formRef = useRef();
  const triggerValidationSchema = makeTriggerValidationSchema(accountId);
  let title;
  if (mode === TriggerModalModes.CREATE) {
    title = "Create a New Trigger";
  } else if (mode === TriggerModalModes.EDIT) {
    title = "Edit Trigger";
  }

  return (
    <Modal {...props}>
      <Modal.Header closeButton className="">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{ ...TriggerShape, ...props.trigger }}
        enableReinitialize
        validationSchema={triggerValidationSchema}
        validateOnChange={false}
        onSubmit={values => {
          // Create the trigger
          handleCreate(values);
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched
        }) => (
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Key</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="help"
                  name="key"
                  className={touched.key && errors.key ? "border-danger" : ""}
                  onKeyDown={e => {
                    // Don't allow space character
                    if (e.which === 32) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  onChange={e => {
                    // Remove spaces in case they've been pasted.
                    e.target.value = e.target.value.replace(/\s/g, "");
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.key}
                />
                <Form.Text className="text-muted">
                  {touched.key && errors.key
                    ? errors.key
                    : "Single word key which Gatekeeper will look for in messages."}
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Response to Sender</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="5"
                  placeholder="Here's a list of the things we can do!"
                  name="responseToSender"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.responseToSender}
                  className={
                    touched.responseToSender && errors.responseToSender
                      ? "border-danger"
                      : ""
                  }
                />
                <Form.Text className="text-muted">
                  {touched.responseToSender && errors.responseToSender
                    ? errors.responseToSender
                    : "When the key is found in an incoming message, this is what will be sent to the sender."}
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="triggerNotificationList">
                <Form.Check
                  label="Should Trigger Listeners?"
                  name="triggerNotificationList"
                  id="triggerNotificationList"
                  checked={values.triggerNotificationList}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
              {values.triggerNotificationList && (
                <Form.Group>
                  <Form.Label>Message to Listeners</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="5"
                    placeholder="A user with phone number {from} is trying to get some help."
                    name="messageToListeners"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.messageToListeners}
                    className={
                      touched.messageToListeners && errors.messageToListeners
                        ? "border-danger"
                        : ""
                    }
                  />
                  <Form.Text className="text-muted">
                    {touched.messageToListeners && errors.messageToListeners
                      ? errors.messageToListeners
                      : "When the key is found in an incoming message, this is what will be sent to the sender."}
                  </Form.Text>
                </Form.Group>
              )}
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <Form.Group controlId="enabled">
                <Form.Check
                  label="Enabled"
                  name="enabled"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.enabled}
                />
              </Form.Group>
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={() => props.onHide()}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Save Trigger
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
