import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyCNaWWm7zKXpuFE66bYpcAeLHjSgNQilxQ",
  databaseURL: "https://endgames-door.firebaseio.com",
  storageBucket: "endgames-door.appspot.com",
  authDomain: "endgames-door.firebaseapp.com",
  messagingSenderId: "1033790174877",
  projectId: "endgames-door"
});

const firestore = firebase.firestore();
const settings = {};
firestore.settings(settings);

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();

export const refs = {
  accounts: firestore.collection("accounts"),
  authorizedUsers: firestore.collection("authorized-users"),
  triggers: firestore.collection("triggers")
};

export default firebase;
