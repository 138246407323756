import { createSlice } from "@reduxjs/toolkit";
import { auth, provider, refs } from "../util/firebase";

const userSlice = createSlice({
  name: "user",
  initialState: {
    accountId: null,
    account: null,
    auth: null,
    error: null,
    isLoading: false
  },
  reducers: {
    loginStarted(state, action) {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess(state, action) {
      const { displayName, email, photoURL } = action.payload;
      state.auth = { displayName, email, photoURL };
      state.isLoading = false;
      getAccountInfo();
    },
    loginFail(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    logoutStarted(state, action) {
      state.isLoading = true;
    },
    logoutSuccess(state, action) {
      state.isLoading = false;
      state.auth = null;
    },
    logoutFail(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    accountInfoStart(state, action) {
      state.isLoading = true;
    },
    accountInfoSuccess(state, action) {
      state.accountId = action.payload.accountId;
      state.account = action.payload;
      state.isLoading = false;
    },
    accountInfoFail(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const {
  loginStarted,
  loginSuccess,
  loginFail,
  logoutStarted,
  logoutSuccess,
  logoutFail,
  accountInfoStart,
  accountInfoSuccess,
  accountInfoFail
} = userSlice.actions;

export const parseUserData = ({ displayName, email, photoURL }) => ({
  displayName,
  email,
  photoURL
});

export const authStateChanged = user => async dispatch => {
  if (user) {
    dispatch(loginSuccess(parseUserData(user)));
    dispatch(getAccountInfo(user.email));
  } else {
    console.log(user);
  }
};

export const login = () => async dispatch => {
  dispatch(loginStarted());
  try {
    const data = await auth.signInWithRedirect(provider);
    if (data.user) {
      console.log(data.user);
      dispatch(loginSuccess(parseUserData(data.user)));
    }
  } catch (err) {
    dispatch(loginFail(err.message));
    return;
  }
};

export const logout = () => async dispatch => {
  dispatch(logoutStarted());

  try {
    await auth.signOut();
    dispatch(logoutSuccess());
  } catch (err) {
    dispatch(logoutFail(err.toString()));
    return;
  }
};

export const getAccountInfo = email => async dispatch => {
  dispatch(accountInfoStart());

  try {
    const userSnap = await refs.authorizedUsers
      .where("email", "==", email)
      .limit(1)
      .get();

    const accountSnap = await refs.accounts
      .where("owner", "==", userSnap.docs[0].ref)
      .get();

    const { owner, ...accountDetails } = accountSnap.docs[0].data();

    const accountData = {
      accountId: accountSnap.docs[0].id,
      ownerId: owner.id,
      ...accountDetails
    };

    dispatch(accountInfoSuccess(accountData));
  } catch (err) {
    dispatch(accountInfoFail(err.toString()));
  }
};

export default userSlice.reducer;
