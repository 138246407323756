import * as Yup from "yup";
import * as libphonenumber from "google-libphonenumber";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

Yup.addMethod(Yup.string, "phone", function(
  message = "Must be a phone number",
  countryCode = "US"
) {
  return this.test({
    name: "phone",
    exclusive: true,
    message,
    test: value => {
      try {
        const phone = phoneUtil.parse(value, countryCode);
        return phoneUtil.isValidNumber(phone);
      } catch (e) {
        return false;
      }
    }
  });
});

export default Yup;
