import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInterval from "use-interval";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

import { Button, Row, Table, Badge, Spinner, Dropdown } from "react-bootstrap";

import { fetchTriggers } from "../reducers/triggers";
import { fixNewlines, tokenize } from "../util/string";

import { createTrigger, deleteTrigger, updateTrigger } from "../api/triggers";

import { TriggerModal, TriggerModalModes } from "./modals/triggerModal";

const MonoWrap = ({ children }) => (
  <pre style={{ whiteSpace: "pre-wrap" }}>{children}</pre>
);

const MessageToListener = ({ trigger }) => {
  const classnames = [];
  if (trigger.triggerNotificationList === false) {
    classnames.push("table-secondary");
  }

  return (
    <td className={classnames.join(" ")}>
      {trigger.triggerNotificationList ? (
        <MonoWrap>{tokenize(fixNewlines(trigger.messageToListeners))}</MonoWrap>
      ) : (
        <span>Trigger doesn't trigger a notification list.</span>
      )}
    </td>
  );
};

// Fetch every minute
const TRIGGER_FETCHING_INTERVAL = 60 * 1000; // 1m
// for 5 minutes
const MAX_AUTO_FETCHES = 5;

const TriggerStatusBadge = ({ trigger }) => {
  const config = trigger.enabled
    ? { variant: "success", children: "Enabled" }
    : { variant: "danger", children: "Disabled" };
  return <Badge {...config} />;
};

export default function Triggers() {
  const dispatch = useDispatch();

  const [stale, setStale] = useState(false);
  const [autoFetchInterval, setAutoFetchInterval] = useState(
    TRIGGER_FETCHING_INTERVAL
  );
  const [autoFetches, setAutoFetches] = useState(0);

  const [modalIsShowing, setModalIsShowing] = useState(false);
  const [modalMode, setModalMode] = useState(TriggerModalModes.CREATE);
  const [selectedTrigger, setSelectedTrigger] = useState({});

  const { isLoading, data } = useSelector(state => state.triggers);
  const { accountId } = useSelector(state => state.user);

  const resetAutoFetching = () => {
    setStale(false);
    setAutoFetchInterval(TRIGGER_FETCHING_INTERVAL);
    setAutoFetches(0);
  };

  const loadTriggers = (reset = false) => {
    dispatch(fetchTriggers(accountId));

    if (reset) {
      resetAutoFetching();
    }
  };

  const uiDeleteTrigger = async id => {
    await deleteTrigger(id);
    loadTriggers();
  };

  const editTrigger = trigger => {
    setSelectedTrigger(trigger);
    setModalMode(TriggerModalModes.EDIT);
    setModalIsShowing(true);
  };

  const setEnabled = async (trigger, enabled) => {
    await updateTrigger(trigger._id, { ...trigger, enabled });
    loadTriggers();
  };

  // TODO: save last loaded time, on an interval if the time is greater than a specific amount, auto reload
  useInterval(() => {
    console.log(autoFetches);
    if (autoFetches >= MAX_AUTO_FETCHES - 1) {
      setAutoFetchInterval(false);
      setStale(true);
      return;
    } else {
      loadTriggers();
      setAutoFetches(autoFetches + 1);
    }
  }, autoFetchInterval);

  useEffect(() => {
    loadTriggers();
    // including loadTriggers in the deps for this hook causes infinite loading, and $$$ due to firebase being overloaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return (
    <div className="p-3">
      <Row className="mb-3 justify-content-between">
        <div className="d-flex align-items-center">
          <h3 className="pr-2">Triggers</h3>
          {isLoading && <Spinner animation="border" size="sm" />}
          {stale && (
            <Button
              variant="link"
              title="Triggers might be out of date"
              onClick={() => loadTriggers(true)}
            >
              Reload Triggers
            </Button>
          )}
        </div>
        <Button
          variant="outline-primary"
          onClick={() => {
            setModalIsShowing(true);
            setSelectedTrigger({});
          }}
        >
          Create Trigger
        </Button>
      </Row>

      <Row>
        <Table bordered responsive>
          <thead className="table-dark">
            <tr>
              <th>Key</th>
              <th>Response to Sender</th>
              <th>Message to Listeners</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map(trigger => (
              <tr key={trigger.key}>
                <th>
                  <div>{trigger.key}</div>
                  <TriggerStatusBadge trigger={trigger} />
                </th>
                <td>
                  <MonoWrap>
                    {tokenize(fixNewlines(trigger.responseToSender))}
                  </MonoWrap>
                </td>
                <MessageToListener trigger={trigger} />
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-dark">
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="dark">
                      <Dropdown.Item onClick={() => editTrigger(trigger)}>
                        Edit Trigger
                      </Dropdown.Item>
                      {trigger.enabled ? (
                        <Dropdown.Item
                          onClick={() => setEnabled(trigger, false)}
                        >
                          Disable Trigger
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          onClick={() => setEnabled(trigger, true)}
                        >
                          Enable Trigger
                        </Dropdown.Item>
                      )}
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => uiDeleteTrigger(trigger._id)}
                      >
                        <span className="text-danger">
                          {/* <FontAwesomeIcon icon={faTrashAlt} />{" "} */}
                          Delete Trigger
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>

      <TriggerModal
        show={modalIsShowing}
        mode={modalMode}
        trigger={selectedTrigger}
        accountId={accountId}
        onHide={() => setModalIsShowing(false)}
        handleCreate={async values => {
          if (modalMode === TriggerModalModes.CREATE) {
            await createTrigger(accountId, values);
          } else if (modalMode === TriggerModalModes.EDIT) {
            await updateTrigger(selectedTrigger._id, values);
          }

          setModalIsShowing(false);
          loadTriggers();
        }}
      />
    </div>
  );
}
