import { createSlice } from "@reduxjs/toolkit";

import { refs } from "../util/firebase";

const triggerSlice = createSlice({
  name: "triggers",
  initialState: {
    data: [],
    error: null,
    isLoading: false,
    fetchedAt: null
  },
  reducers: {
    fetchTriggersStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchTriggersSuccess(state, action) {
      state.data = action.payload;
      state.isLoading = false;
      state.fetchedAt = Date.now();
    },
    fetchTriggersFailed(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});

export const {
  fetchTriggersStart,
  fetchTriggersSuccess,
  fetchTriggersFailed
} = triggerSlice.actions;

export const fetchTriggers = account => async dispatch => {
  dispatch(fetchTriggersStart());
  try {
    const triggerSnap = await refs.triggers
      .where("account", "==", account)
      .get();
    const triggers = [];

    triggerSnap.forEach(trigger => {
      const { notificationListRef, ...t } = trigger.data();
      triggers.push({ ...t, _id: trigger.id });
    });

    dispatch(fetchTriggersSuccess(triggers));
  } catch (err) {
    dispatch(fetchTriggersFailed(err.toString()));
    return;
  }
};

export default triggerSlice.reducer;
