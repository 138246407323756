import React, { useEffect } from "react";

import { auth } from "./util/firebase";

import { useSelector, useDispatch } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { Container, Navbar, Row, Col, Nav, Alert } from "react-bootstrap";

import {
  Switch,
  Route,
  Link as RouterLink,
  useLocation
} from "react-router-dom";

import Home from "./pages/home";
import Accounts from "./pages/accounts";
import Triggers from "./pages/triggers";
import NotificationLists from "./pages/notificationLists";
import Settings from "./pages/settings";

import { login, logout, authStateChanged } from "./reducers/user";

const Link = props => <Nav.Link {...props} href={props.to} as={RouterLink} />;

const App = () => {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleLogin = () => dispatch(login());
  const handleLogout = () => dispatch(logout());

  useEffect(() => {
    auth.onAuthStateChanged(user => dispatch(authStateChanged(user)));
  }, [dispatch]);

  return (
    <Container fluid>
      <Navbar expand="lg" bg="light" fixed="top">
        <Navbar.Brand href="/">
          Gatekeeper {user.account ? ` | ${user.account.name}` : ""}
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            {user.auth ? (
              <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
            ) : (
              <Nav.Link onClick={handleLogin}>Login</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {user.error && <Alert>{user.error}</Alert>}

      {!user.auth ? (
        <div>Login</div>
      ) : (
        <Row>
          {/* Menu */}
          <Col style={{ maxWidth: 200 }} bg="light">
            <Nav
              variant="light"
              defaultActiveKey={location.pathname}
              className="flex-column"
              activeKey={location.pathname}
            >
              <Link to="/">Home</Link>
              <Link to="/accounts">Accounts</Link>
              <Link to="/triggers">Triggers</Link>
              <Link to="/notification-lists">Notification Lists</Link>
              <Link to="/settings">Settings</Link>
            </Nav>
          </Col>
          <Col>
            {/* Body */}
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/accounts">
                <Accounts />
              </Route>
              <Route path="/triggers">
                <Triggers />
              </Route>
              <Route path="/notification-lists">
                <NotificationLists />
              </Route>
              <Route path="/settings">
                <Settings />
              </Route>
            </Switch>
          </Col>
        </Row>
      )}
    </Container>
  );
};

// class App extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       loading: true,
//       stageManagersMap: {},
//       stageManagersArr: [],
//       user: false
//     };

//     this.stageManagersRef = null;
//   }

//   // async componentDidMount() {
//   //   // this.stageManagersRef = firebase.firestore().collection("stagemanagers");
//   //   // Handle login events.
//   //   // auth.onAuthStateChanged(user => {
//   //   //   if (user) {
//   //   //     this.setState({ user });
//   //   //     console.log(user);
//   //   //   }
//   //   // });
//   //   // this.stageManagersRef.onSnapshot(({ docs }) => {
//   //   //   this.setState({
//   //   //     stageManagersArr: docs.map(doc => ({ id: doc.id, ...doc.data() })),
//   //   //     loading: false
//   //   //   });
//   //   // });
//   //   // const triggersSnap = await refs.triggers
//   //   //   .where("account", "==", "cTnOTJk0r4DnbBBb1afe")
//   //   //   .get();
//   //   // console.log(triggersSnap);
//   //   // const triggers = [];
//   //   // triggersSnap.forEach(ts => triggers.push(ts.data()));
//   //   // console.log(triggers);
//   // }

//   // toggleSendNotification = (index, updateRemotely = true) => {
//   //   const next = [...this.state.stageManagersArr];
//   //   next[index].shouldBeNotified = !next[index].shouldBeNotified;

//   //   const { id, ...doc } = next[index];

//   //   this.setState(
//   //     {
//   //       stageManagersArr: next
//   //     },
//   //     () => {
//   //       this.stageManagersRef
//   //         .doc(id)
//   //         .set(doc)
//   //         .then(null, err => {
//   //           console.error(err);
//   //           this.toggleSendNotification(index);
//   //         });
//   //     }
//   //   );
//   // };

//   clearAll = () => {
//     if (
//       window.confirm(
//         "Are you sure you want to clear all Stage Managers for today?"
//       )
//     ) {
//       const next = [...this.state.stageManagersArr];
//       next.forEach(sm => {
//         sm.shouldBeNotified = false;
//         const { id, ...doc } = sm;

//         this.stageManagersRef.doc(id).set(doc);
//       });

//       this.setState({
//         stageManagersArr: next
//       });
//     }
//   };

//   enabledClearAllButton = () => {
//     let returnVal = false;

//     this.state.stageManagersArr.forEach(sm => {
//       if (sm.shouldBeNotified) {
//         returnVal = true;
//       }
//     });
//     return !returnVal;
//   };

//   logout = () => {
//     auth.signOut().then(() => {
//       this.setState({
//         user: false
//       });
//     });
//   };

//   login = () => {
//     auth.signInWithPopup(provider).then(res => {
//       const user = res.user;
//       this.setState({
//         user
//       });
//     });
//   };

//   // renderTable = () => {
//   //   return (
//   //     <div className={this.props.classes.paddedBody}>
//   //       <Toolbar disableGutters className={this.props.classes.toolbar}>
//   //         <Typography variant="h5" gutterBottom>
//   //           Stage Managers
//   //         </Typography>
//   //         <Button
//   //           disabled={this.enabledClearAllButton()}
//   //           variant="text"
//   //           color="secondary"
//   //           onClick={this.clearAll}
//   //         >
//   //           Clear all
//   //         </Button>
//   //       </Toolbar>
//   //       <Paper>
//   //         <Table>
//   //           <TableHead>
//   //             <TableRow>
//   //               <TableCell>Name</TableCell>
//   //               <TableCell>Phone</TableCell>
//   //               <TableCell>Send Door Notifications</TableCell>
//   //             </TableRow>
//   //           </TableHead>
//   //           <TableBody>
//   //             {this.state.stageManagersArr.map((sm, index) => {
//   //               return (
//   //                 <TableRow key={sm.id}>
//   //                   <TableCell>{sm.name}</TableCell>
//   //                   <TableCell>{sm.phone}</TableCell>
//   //                   <TableCell>
//   //                     <Switch
//   //                       onChange={() => this.toggleSendNotification(index)}
//   //                       checked={sm.shouldBeNotified}
//   //                     />
//   //                   </TableCell>
//   //                 </TableRow>
//   //               );
//   //             })}
//   //           </TableBody>
//   //         </Table>
//   //       </Paper>
//   //     </div>
//   //   );
//   // };

//   render() {
//     return (
//       <Provider store={store}>
//         <Router>
//           <Container fluid>
//             <Navbar expand="lg" bg="light" fixed="top">
//               <Navbar.Brand href="/">Gatekeeper</Navbar.Brand>

//               <Navbar.Toggle aria-controls="basic-navbar-nav" />
//               <Navbar.Collapse
//                 id="basic-navbar-nav"
//                 className="justify-content-end"
//               >
//                 <Nav>
//                   {this.state.user ? (
//                     <Nav.Link onClick={this.logout}>Logout</Nav.Link>
//                   ) : (
//                     <Nav.Link onClick={this.login}>Login</Nav.Link>
//                   )}
//                 </Nav>
//               </Navbar.Collapse>
//             </Navbar>

//             {!this.state.user ? (
//               <div>Login</div>
//             ) : (
//               <Row>
//                 {/* Menu */}
//                 <Col style={{ maxWidth: 200 }} bg="light">
//                   <Nav
//                     variant="light"
//                     defaultActiveKey="/"
//                     className="flex-column"
//                     activeKey="/"
//                   >
//                     <Link to="/">Home</Link>
//                     <Link to="/triggers">Triggers</Link>
//                     <Link to="/notification-lists">Notification Lists</Link>
//                     <Link to="/settings">Settings</Link>
//                   </Nav>
//                 </Col>
//                 <Col>
//                   {/* Body */}
//                   <Switch>
//                     <Route exact path="/">
//                       <Home />
//                     </Route>
//                     <Route path="/triggers">
//                       <Triggers />
//                     </Route>
//                     <Route path="/notification-lists">
//                       <NotificationLists />
//                     </Route>
//                     <Route path="/settings">
//                       <Settings />
//                     </Route>
//                   </Switch>
//                 </Col>
//               </Row>
//             )}
//           </Container>
//         </Router>
//       </Provider>
//     );
//   }
// }

export default App;
