import { refs } from "../util/firebase";

const triggers = refs.triggers;

export const checkTriggerKeyIsUnique = async (accountId, key) => {
  const triggerSnap = await triggers
    .where("account", "==", accountId)
    .where("key", "==", key)
    .get();

  return !!triggerSnap.empty;
};

export const createTrigger = async (accountId, values) => {
  // console.log("createTrigger", accountId, values);
  const doc = await triggers.add({
    ...values,
    account: accountId
  });
  console.log(doc);
  return doc;
};

export const deleteTrigger = async id => {
  if (
    window.confirm(
      `Are you sure you want to delete this trigger? This cannot be undone.`
    )
  ) {
    await triggers.doc(id).delete();
  }
};

export const updateTrigger = async (id, { _id, ...values }) => {
  await triggers.doc(id).update(values);
};
