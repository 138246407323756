import React, { useState, useEffect } from "react";
import {
  Row,
  Spinner,
  Form,
  FormGroup,
  FormControl,
  Button
} from "react-bootstrap";

import equal from "deep-equal";

import { useSelector } from "react-redux";
import { Formik } from "formik";

import { titleCase, fixNewlines } from "../util/string";

import Yup from "../util/validation";
import { updateAccount } from "../api/accounts";

const FormModes = {
  VIEW: "VIEW",
  EDIT: "EDIT"
};

const FormFields = [
  {
    name: "accountId",
    editable: false,
    visible: false,
    infoText: "Your account ID.",
    validation: Yup.string().required()
  },
  {
    name: "ownerId",
    editable: false,
    visible: false,
    infoText: "The ID of the owner of this account.",
    validation: Yup.string().required()
  },
  {
    name: "name",
    editable: true,
    validation: Yup.string()
      .min(5, "Must be longer than 5 characters.")
      .required(),
    visible: true,
    infoText: "The name for this GateKeeper account."
  },
  {
    name: "phone",
    editable: true,
    validation: Yup.string()
      .phone()
      .required(),
    visible: true,
    infoText:
      "The incoming Phone Number for the linked Twilio Messaging Service."
  },
  {
    name: "twilioMessagingServiceSID",
    editable: true,
    validation: Yup.string()
      .length(34, "Must be a 34 character Twilio Messaging Service SID.")
      .required(),
    visible: true,
    infoContainsHtml: true,
    infoText:
      "The Twilio Messaging Service SID. See: <a target='_blank' rel='noopener noreferrer' href='https://www.twilio.com/console/sms/services'>https://www.twilio.com/console/sms/services</a>"
  },
  {
    name: "unknownMessageResponse",
    editable: true,
    visible: true,
    type: "textarea",
    validation: Yup.string().required(),
    formatter: fixNewlines,
    infoText:
      "The message sent to the original sender should their message not contain any triggers."
  }
];

const FormValidationSchema = Yup.object().shape(
  FormFields.filter(f => f.validation).reduce(
    (acc, curr) => ({ ...acc, [curr.name]: curr.validation }),
    {}
  )
);

const Accounts = () => {
  const [mode, setMode] = useState(FormModes.VIEW);

  const { account } = useSelector(state => state.user);

  useEffect(() => {
    setFormValues(account);
  }, [account]);

  const [formValues, setFormValues] = useState(account);

  const handleSubmit = values => {
    const { accountId, ...accountValues } = values;
    updateAccount(accountId, accountValues);
    setFormValues(values);
    setMode(FormModes.VIEW);
  };

  return (
    <div className="p-3">
      {account && formValues ? (
        <Formik
          initialValues={formValues}
          enableReinitialize
          validationSchema={FormValidationSchema}
          onSubmit={values => handleSubmit(values)}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            initialValues
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row className="justify-content-between">
                <h3>Accounts</h3>
                {mode === FormModes.VIEW ? (
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={e => {
                      e.preventDefault();
                      setMode(FormModes.EDIT);
                    }}
                  >
                    Edit
                  </Button>
                ) : equal(values, initialValues) ? (
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={() => setMode(FormModes.VIEW)}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                )}
              </Row>
              {FormFields.map(
                f =>
                  f.visible && (
                    <Row key={f.name}>
                      <FormGroup key={f.name}>
                        <Form.Label>{titleCase(f.name)}</Form.Label>
                        <FormControl
                          name={f.name}
                          value={
                            f.formatter
                              ? f.formatter(values[f.name])
                              : values[f.name]
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={mode === FormModes.VIEW || !f.editable}
                          as={f.type || "input"}
                          rows={3} // TODO: make this conditional on f.type
                          className={
                            touched[f.name] && errors[f.name]
                              ? "border-danger"
                              : ""
                          }
                        ></FormControl>
                        <Form.Text className="text-muted">
                          {touched[f.name] && errors[f.name] ? (
                            errors[f.name]
                          ) : f.infoContainsHtml ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: f.infoText
                              }}
                            ></span>
                          ) : (
                            f.infoText
                          )}
                        </Form.Text>
                      </FormGroup>
                    </Row>
                  )
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner animation="border" />
      )}
    </div>
  );
};

export default Accounts;
