import React from "react";

import { Badge } from "react-bootstrap";

import { capitalCase } from "change-case";

export const fixNewlines = str => str.replace(/\\n/g, "\n");

export const tokenRegex = /(\{\s?\w+\s?\})/g;

export const tokenize = str => {
  const tokens = str.split(tokenRegex);
  const output = (
    <span>
      {tokens.map(tkn => {
        if (tokenRegex.test(tkn)) {
          return (
            <Badge key={tkn} variant="secondary">
              {tkn}
            </Badge>
          );
        }
        return <span key={tkn}>{tkn}</span>;
      })}
    </span>
  );

  return output;
};

export const prettyJSON = obj => JSON.stringify(obj, null, 4);

export const titleCase = capitalCase;
